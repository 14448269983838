import { PaymentStrategy, SubscriptionStatus } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import { differenceInDays } from 'date-fns'
import React from 'react'

import AddOnlineStoreModal from 'cms/onlineStores/components/AddOnlineStoreModal'
import { useSubscriptionService } from 'cms/subscription/hooks'
import { Button, useModal } from 'common/components'
import CarretRight from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-right-1.svg'
import classMerge from 'utils/classMerge'

import { useOnlineStoreService } from '../onlineStores'
import { SubscriptionFullPageModal } from '../subscription/components/modals'

const FreeTrialIndicator = () => {
  const addOnlineStoreModal = useModal()
  const subscriptionService = useSubscriptionService()
  const { data: subscription, isLoading } = useQuery(
    subscriptionService.fetchOne.queryKeys,
    subscriptionService.fetchOne
  )
  const isManualPayment = subscription && subscription.paymentStrategy === PaymentStrategy.Manual

  const subscribeModal = useModal()

  const onlineStoreService = useOnlineStoreService()
  const { data: onlineStores } = useQuery(onlineStoreService.fetchAll.queryKeys, onlineStoreService.fetchAll, {
    initialData: [],
  })
  const isInstalled = onlineStores.length > 0
  const stripeFreeTrialActivated =
    subscription?.status === SubscriptionStatus.FreeTrial &&
    subscription.paymentStrategy === PaymentStrategy.Stripe &&
    !!subscription.stripePaymentMethodId

  if (isLoading || subscription?.status !== SubscriptionStatus.FreeTrial || stripeFreeTrialActivated) return null

  const daysDiff = differenceInDays(new Date(subscription.freeTrialEndsOn).getTime(), new Date().getTime())

  const textColorClassName = classMerge('text-tertiary-red-700', {
    'text-secondary-orange-400': daysDiff > 2 && daysDiff <= 6,
    'text-tertiary-green-400': daysDiff > 6,
  })

  return (
    <div className="flex items-center" aria-label="free trial indicator">
      {!isInstalled && (
        <div className="flex items-center mr-1">
          1.&nbsp;
          <Button className="ml-1" onClick={() => addOnlineStoreModal.open()}>
            Install app
          </Button>
          <CarretRight className="w-2 fill-current text-current mx-3" />
          2.&nbsp;
        </div>
      )}
      <Button variant="green" onClick={() => subscribeModal.open()} disabled={!isInstalled || isManualPayment}>
        Activate
      </Button>
      <div className="flex ml-2">
        {daysDiff < 0 && (
          <>
            Free trial has&nbsp;<span className={textColorClassName}>ended</span>
          </>
        )}
        {daysDiff > 0 && (
          <>
            Free trial ends in&nbsp;<span className={textColorClassName}>{daysDiff + 1}</span>&nbsp;days
          </>
        )}
        {daysDiff === 0 && (
          <>
            Free trial ends&nbsp;<span className={textColorClassName}>today</span>
          </>
        )}
      </div>
      {addOnlineStoreModal.isVisible && (
        <AddOnlineStoreModal onClose={addOnlineStoreModal.close} {...addOnlineStoreModal.modalProps} />
      )}
      {subscribeModal.isVisible && (
        <SubscriptionFullPageModal onClose={subscribeModal.close} {...subscribeModal.modalProps} />
      )}
    </div>
  )
}

export default FreeTrialIndicator

import { Answer, ColorView } from '@packages/types'
import classNames from 'classnames'
import * as React from 'react'
import tinycolor from 'tinycolor2'

import withAnswerTheme, { WithAnswerThemeProps } from './withAnswerTheme'

type ColorAnswerProps = Answer &
  WithAnswerThemeProps & {
    family?: { style: React.CSSProperties }
    cssStyle?: React.CSSProperties
  }

const ColorAnswer = withAnswerTheme<ColorAnswerProps>(
  ({ views, family, style: providedStyle, extraClassNames, cssStyle }) => {
    const colorValue = (views as ColorView[] | undefined)?.find(view => view.color)?.color

    let style = {
      ...providedStyle,
      ...cssStyle,
      backgroundColor: tinycolor(colorValue).toHexString(),
    }

    if (family) {
      style = Object.assign(style, family.style)
    }

    return <div className={classNames('color-choice', extraClassNames)} style={style} data-testid="color-answer" />
  }
)

export default ColorAnswer

import { actions as designsActions } from 'customizer/designs'

export const shareDesign = (onSuccessCallBack: () => void) => {
  return async (dispatch: (dispatch?: any, getState?: any) => Promise<any>) => {
    const design = await new Promise(resolve => {
      dispatch(designsActions.generateDesign())
        .then((response: Response) => {
          onSuccessCallBack()
          resolve(response)
        })
        .catch(() => {
          resolve(undefined)
        })
    })

    parent.postMessage({ eventName: 'mczrSave', detail: design }, '*')
  }
}

import { EntityType, GroupType } from '@packages/types'

import { utils as customizationUtils } from 'customizer/customization'

const { stepHasQuestionWithAvailableAnswers, stepIsAvailable } = customizationUtils

export const getChildrenInQuestionPanel = ({ children, ignoreIsHiddenWhenEmpty = false }) => {
  return children.filter(child => {
    return stepHasQuestionWithAvailableAnswers(child, ignoreIsHiddenWhenEmpty)
  })
}

export const flattenChildren = children => {
  return children.reduce((children, child) => {
    if (child.entityType === EntityType.Question) return [...children, child]

    return [...children, ...flattenChildren(child.children)]
  }, [])
}

export const flattenFolders = (step, maxNestingLevel, nestingLevel = 0) => {
  if (
    step.entityType === EntityType.Question ||
    (step.entityType === EntityType.Group && step.type === GroupType.BulkOrder)
  )
    return step

  const nextNestingLevel = nestingLevel + 1

  const children = step.children.reduce((children, step) => {
    const flattenedStep = flattenFolders(step, maxNestingLevel, nextNestingLevel)

    if (Array.isArray(flattenedStep)) return [...children, ...flattenedStep]

    return [...children, flattenedStep]
  }, [])

  if (maxNestingLevel <= nestingLevel) return children

  return { ...step, children }
}

export const getAvailableSteps = step => {
  if (step.entityType === EntityType.Group && step.type === GroupType.BulkOrder) return []

  return step.children?.filter(step => {
    if (step.type === GroupType.BulkOrder) {
      return true
    }

    return stepIsAvailable(step)
  })
}

export const getFirstAvailableStep = step => {
  let firstAvailableStep = step
  let steps = getAvailableSteps(firstAvailableStep)

  while (steps?.length > 0) {
    firstAvailableStep = steps[0]
    steps = getAvailableSteps(firstAvailableStep)
  }

  return firstAvailableStep
}

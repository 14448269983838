import onPluginUpdate from './onPluginUpdate'
import { PluginComponent, PluginTexts, PluginUpdateFunction } from './types'

export default (
  name: string,
  folderName: string,
  version: string,
  script: { initialize: () => void },
  components: PluginComponent[] = [],
  Settings: (() => JSX.Element) | undefined,
  texts: PluginTexts = {},
  updateFunctions: Record<string, PluginUpdateFunction> = {}
) => {
  return {
    spec: {
      name,
      active: false,
      native: true,
      location: folderName,
      settings: {},
      texts,
      translations: {},
      version,
      onPluginUpdate: onPluginUpdate(updateFunctions),
    },
    script,
    components,
    Settings,
  }
}

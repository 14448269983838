import React from 'react'

import { Popover, usePopover } from 'common/components'
import QuestionCircleIcon from 'icons/regular/01-Interface Essential/14-Alerts/question-circle.svg'

const ChangeOnlineStoreInstructionsPopover = () => {
  const popover = usePopover({ placement: 'top', offsetConfig: 32, useArrow: true })
  return (
    <>
      <span
        aria-label="change online store instruction popover"
        onMouseEnter={() => popover.open()}
        onMouseLeave={() => popover.close()}
        {...popover.referenceProps}
      >
        <QuestionCircleIcon className="fill-neutral-900 w-3 h-3" />
      </span>
      <Popover {...popover.floatingProps} isOpen={popover.isOpen} className="p-4 text-neutral-600">
        <div className="flex flex-col">
          <span className="text-sm font-bold pb-2">Changing your payment store</span>
          <ul className="text-xs font-medium">
            <li>1. Cancel your original subscription</li>
            <li>2. Re-activate your subscription </li>
            <li>3. Choose the desired payment store</li>
            <li>4. Press Subscribe!</li>
          </ul>
        </div>
      </Popover>
    </>
  )
}

export default ChangeOnlineStoreInstructionsPopover

import { merge } from 'lodash'
import { createSelector } from 'reselect'

import { quoteSelector } from 'customizer/quote/selectors'
import { RootState } from 'customizer/store'
import { selectors as themeSelectors } from 'customizer/theme'

export const translationSelector = ({ translation }: RootState) => translation

export const themeTranslationSelector = createSelector(
  translationSelector,
  themeSelectors.themeSelector,
  (translation, theme) => {
    return merge({}, theme.texts, translation?.static?.[theme.name!] || {})
  }
)

export const quoteTranslationSelector = createSelector(translationSelector, quoteSelector, (translation, quote) => {
  return merge({}, quote.texts, translation?.static?.quote || {})
})

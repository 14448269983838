import UAParser from 'ua-parser-js'

export const isIOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export default () => {
  const { device } = UAParser(navigator.userAgent)

  const isIOS13IPad =
    /iPad/.test(window.navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && !window.MSStream)

  return !!device.type && (['mobile', 'tablet'].includes(device.type) || isIOS13IPad)
}

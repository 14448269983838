import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Popover, usePopover } from 'common/components'
import CheckCircleIcon from 'icons/bold/01-Interface Essential/33-Form-Validation/check-circle-1.svg'
import StarsIcon from 'icons/custom/stars-3.svg'

export interface ActivatePlanPopoverProps {
  baseUrl: string
  isCompleted: boolean
}

const ActivatePlanPopover = ({ baseUrl, isCompleted }: ActivatePlanPopoverProps) => {
  const popover = usePopover({ placement: 'right', offsetConfig: 32, useArrow: true })

  return (
    <>
      <button
        {...popover.referenceProps}
        className={classNames('font-p2 flex items-center justify-between h-4', {
          'text-tertiary-green-300': isCompleted,
          'text-primary-600 font-bold': popover.isOpen,
        })}
        disabled={isCompleted}
      >
        4. Activate your plan
        {isCompleted && <CheckCircleIcon className="w-4 h-4 fill-tertiary-green-300" aria-label="completed" />}
      </button>

      <Popover {...popover.floatingProps} isOpen={popover.isOpen} className="!w-80 shadow-lg">
        <div>
          <div className="flex flex-col p-4 space-y-4">
            <div className="w-[45px] h-[45px] rounded-lg bg-neutral-75 p-2">
              <StarsIcon />
            </div>
            <span className="text-xs font-medium text-neutral-600">
              Activate your plan and continue to sell through our app.
            </span>
          </div>
          <div className="px-4 py-3 flex justify-end border-t border-t-neutral-75">
            <Link to={`${baseUrl}/subscription`}>
              <Button type="button" variant="green" className="px-4">
                Activate
              </Button>
            </Link>
          </div>
        </div>
      </Popover>
    </>
  )
}

export default ActivatePlanPopover

import { DenormalizedCustomizableQuestion, EntityType, DenormalizedCustomizableGroup, GroupType } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import { SanitizedHtml } from 'common/components'
import { usePrevious } from 'common/hooks'
import { utils as customizationUtils } from 'customizer/customization'
import { DescriptionStyle } from 'themes/common/types'

import Popup from './Popup'

const { stepHasQuestionWithAvailableAnswers, isAncestor } = customizationUtils

export interface MobileDescriptionProps {
  step: DenormalizedCustomizableGroup | DenormalizedCustomizableQuestion
  selectedStep?: string
  descriptionStyle: DescriptionStyle
}

const MobileDescription = ({ step, selectedStep, descriptionStyle }: MobileDescriptionProps) => {
  const previousNumberOfSelectedAnswers =
    usePrevious((step?.entityType === EntityType.Question && step?.selectedAnswers?.length) || 0) || 0
  let groupDescription
  let questionDescription
  let selectedAnswerDescription

  let selectedQuestion
  let selectedAnswer

  if (step?.entityType === EntityType.Group) {
    groupDescription = step.showDescription ? step.description : ''
    selectedQuestion = step.children.find(
      child => isAncestor(child, selectedStep) as boolean
    ) as unknown as DenormalizedCustomizableQuestion
  } else if (step) {
    selectedQuestion = step
  }

  if (selectedQuestion) {
    const hasAvailableAnswers = stepHasQuestionWithAvailableAnswers(selectedQuestion)
    questionDescription = selectedQuestion.showDescription && hasAvailableAnswers ? selectedQuestion.description : ''

    const interactionRequired =
      selectedQuestion.entityType === EntityType.Question &&
      !!selectedQuestion.isInteractionRequired &&
      !selectedQuestion.hadInteraction

    if (selectedQuestion.isMultiAnswer) {
      selectedAnswer = selectedQuestion.selectedAnswers![0]
      if (selectedQuestion.selectedAnswers!.length >= previousNumberOfSelectedAnswers) {
        selectedAnswer = selectedQuestion.selectedAnswers![selectedQuestion.selectedAnswers!.length - 1]
      }
    } else {
      selectedAnswer = selectedQuestion.selectedAnswer
    }

    selectedAnswerDescription =
      !!selectedAnswer?.showDescription && !interactionRequired ? selectedAnswer.description : ''
  }

  if (!questionDescription && !selectedAnswerDescription && !groupDescription) return null

  return (
    <div
      className={classNames('description__mobile-container', {
        'bulk-order-description': step.type === GroupType.BulkOrder,
      })}
    >
      {groupDescription && !selectedQuestion && (
        <Popup
          key={`group-description-${step.id}`}
          className="description step-mobile-description"
          style={descriptionStyle}
          aria-label="group description"
        >
          <SanitizedHtml dirtyHtml={groupDescription} tagsStyle={{ a: `color: ${descriptionStyle.linkColor};` }} />
        </Popup>
      )}
      {questionDescription && (
        <Popup
          key={`question-description-${step.id}`}
          className="description option-description"
          style={descriptionStyle}
          aria-label="question description"
        >
          <SanitizedHtml dirtyHtml={questionDescription} tagsStyle={{ a: `color: ${descriptionStyle.linkColor};` }} />
        </Popup>
      )}
      {selectedAnswerDescription && (
        <Popup
          key={`answer-description-${selectedAnswer?.id}`}
          className="description choice-description"
          style={descriptionStyle}
          aria-label="answer description"
        >
          <SanitizedHtml
            dirtyHtml={selectedAnswerDescription}
            tagsStyle={{ a: `color: ${descriptionStyle.linkColor};` }}
          />
        </Popup>
      )}
    </div>
  )
}

export default MobileDescription

import {
  Addon,
  Plan,
  ShopifySubscription,
  SubscriptionStatus,
  ECommerce,
  OnlineStoreStatus,
  ShopifyOnlineStore,
  CustomPlan,
} from '@packages/types'
import { useMutation } from '@tanstack/react-query'
import { FormikHelpers, useFormik } from 'formik'
import { isEqual } from 'lodash'
import React, { useEffect } from 'react'
import * as yup from 'yup'

import { Button, Card, HelperText, InputField, Label, Select, useToast } from 'common/components'
import classMerge from 'utils/classMerge'

import useSubscriptionService from '../../../hooks/useSubscriptionService'
import { ShopifyActivateResponse, ShopifyActivateSubscriptionBody } from '../../../types'
import ChangeOnlineStoreInstructionsPopover from './ChangeOnlineStoreInstructionsPopover'
import TotalCardContent from './TotalCardContent'

export interface ShopifyTotalCardProps {
  subscription: ShopifySubscription
  plan: Plan | CustomPlan
  addons: Addon[]
  onlineStores: ShopifyOnlineStore[]
}

interface ActivateShopifyFormValues {
  shopifyDomain: string
  plan: Plan | CustomPlan
  addons: Addon[]
}

const formValidation = yup.object().shape({
  plan: yup.object().required('Please select plan.'),
  addons: yup.array().of(yup.object()),
  shopifyDomain: yup.string().required('Please select onlinestore.'),
})

const ShopifyTotalCard = ({ subscription, plan, addons, onlineStores }: ShopifyTotalCardProps) => {
  const subscriptionService = useSubscriptionService()
  const { openGenericErrorToast } = useToast()

  const onlineStoresOptions = onlineStores.reduce<{ label: string; value: string }[]>((prev, onlineStore) => {
    if (onlineStore.eCommerce === ECommerce.Shopify && onlineStore.status === OnlineStoreStatus.Installed) {
      prev.push({
        label: onlineStore.domain!,
        value: onlineStore.domain!,
      })
    }

    return prev
  }, [])

  const { mutate: activate } = useMutation(subscriptionService.activate<ShopifyActivateResponse>, {
    onSuccess: response => window.location.replace(response.url),
    onError: () => openGenericErrorToast('Your plan has not been activated.'),
  })

  const { mutate: changePlan } = useMutation(subscriptionService.changePlan<ShopifyActivateResponse>, {
    onSuccess: response => window.location.replace(response.url),
    onError: () => {
      openGenericErrorToast('Your plan has not been changed.')
    },
  })

  const handleSubmit = (
    values: ShopifyActivateSubscriptionBody,
    { setSubmitting }: FormikHelpers<ActivateShopifyFormValues>
  ) => {
    const options = { onError: () => setSubmitting(false) }
    if (subscription.status === SubscriptionStatus.Active) {
      return changePlan(values, options)
    }
    return activate(values, options)
  }

  const initialShopifyDomain =
    subscription.status === SubscriptionStatus.Active ? subscription.shopifyDomain! : onlineStoresOptions[0].value

  const formik = useFormik<ActivateShopifyFormValues>({
    initialValues: {
      plan: subscription.plan,
      addons: subscription.addons,
      shopifyDomain: initialShopifyDomain,
    },
    onSubmit: handleSubmit,
    validationSchema: formValidation,
  })

  useEffect(() => {
    formik.setFieldValue('addons', addons)
  }, [addons])

  useEffect(() => {
    formik.setFieldValue('plan', plan)
  }, [plan])

  const isDirty =
    subscription.status !== SubscriptionStatus.Active ||
    subscription.shopifyDomain !== formik.values.shopifyDomain ||
    !isEqual(subscription.plan, plan) ||
    !isEqual(subscription.addons, addons)

  return (
    <Card className="w-[310px] h-full flex-col">
      <form onSubmit={formik.handleSubmit}>
        <Card.Section className="md:flex-col space-y-6">
          <TotalCardContent addons={addons} plan={plan} />
        </Card.Section>
        <Card.Separator className="border-neutral-100" />
        <Card.Section className="md:flex-col pt-6">
          {onlineStoresOptions.length > 1 && subscription.status !== SubscriptionStatus.Active ? (
            <InputField>
              <Label htmlFor="shopifyDomain">Billing store</Label>
              <Select<string>
                inputId="shopifyDomain"
                name="shopifyDomain"
                menuPortalTarget={document.body}
                menuPosition="fixed"
                className="mb-3"
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                value={onlineStoresOptions.find(option => option.value === formik.values.shopifyDomain)}
                options={onlineStoresOptions}
                onChange={option => formik.setFieldValue('shopifyDomain', option!.value)}
                onBlur={formik.handleBlur}
                hasError={formik.touched.shopifyDomain && formik.errors.shopifyDomain != null}
              />
              {formik.touched.shopifyDomain && formik.errors.shopifyDomain != null && (
                <HelperText hasError>{formik.errors.shopifyDomain}</HelperText>
              )}
            </InputField>
          ) : (
            <>
              <div
                className={classMerge('flex items-center ', {
                  'space-x-2': subscription.status === SubscriptionStatus.Active && onlineStores.length > 1,
                })}
              >
                <span className="font-medium text-sm">Billing store</span>
                {subscription.status === SubscriptionStatus.Active && onlineStores.length > 1 && (
                  <ChangeOnlineStoreInstructionsPopover />
                )}
              </div>

              <div className="leading-[48px] text-neutral-600 mb-1" aria-label="shopify domain">
                {formik.values.shopifyDomain}
              </div>
              {formik.touched.shopifyDomain && formik.errors.shopifyDomain != null && (
                <HelperText hasError>{formik.errors.shopifyDomain}</HelperText>
              )}
            </>
          )}
          {/* Issues propagading click event when not wrapped by a div with e.stopPropagation() */}
          <div onClick={e => e.stopPropagation()} className="flex flex-col">
            <Button
              variant="green"
              type="submit"
              isLoading={formik.isSubmitting}
              disabled={formik.isSubmitting || !formik.isValid || !isDirty}
            >
              {subscription.status === SubscriptionStatus.Active ? 'Change plan' : 'Subscribe'}
            </Button>
          </div>
        </Card.Section>
      </form>
    </Card>
  )
}

export default ShopifyTotalCard

import { isEmpty, pick } from 'lodash'
import { connect } from 'react-redux'

import { actions as customizationActions } from 'customizer/customization'

import {
  switchView,
  nextView,
  previousView,
  setZoom,
  forceHighlight,
  selectStep,
  updatePrintArea,
  updatePartPosition,
  scalePartFontSize,
  editPrintArea,
  setDisplayerSize,
} from './actions'
import {
  isZoomDisabledSelector,
  currentViewDrawingNodesSelector,
  forceHighlightSelector,
  dimensionsSelector,
  isZoomableSelector,
  editedPartSelector,
  hiddenPartsSelector,
  preventTransformSelector,
  isTouchSelector,
} from './selectors'
import { RootState } from 'customizer/store'

const mapStateToProps = (state: RootState) => {
  const { width: canvasWidth, height: canvasHeight } = dimensionsSelector(state)

  return {
    isReady: state.customization.isReady,
    isEmpty: isEmpty(state.customization.parts),
    canvases: currentViewDrawingNodesSelector(state),
    hiddenParts: hiddenPartsSelector(state),
    preventTransform: preventTransformSelector(state),
    canvasWidth,
    canvasHeight,
    views: state.customization.customizerProduct.views,
    view: state.displayer.currentView,
    isZoomed: !!state.displayer.zoom,
    forceHighlight: (forceHighlightSelector(state) ? [forceHighlightSelector(state)] : null) as string[] | null,
    isTouch: isTouchSelector(state),
    whiteLabel: state.planFeatures.whiteLabel,
    isZoomable: isZoomableSelector(state),
    isZoomDisabled: isZoomDisabledSelector(state),
    editedCanvas: editedPartSelector(state),
    showGrid: state.displayer.displayGrid,
    boundingBoxStyle: pick(state.theme.settings?.printReady, ['editionColor', 'editionIconColor']),
    displayerDimensions: state.displayer.size,
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  selectSubStep: (id: string) => dispatch(selectStep(id)),
  changeView: (id: number) => dispatch(switchView(id)),
  editPart: (partId: string) => dispatch(customizationActions.editPart(partId)),
  stopEditPart: () => dispatch(customizationActions.stopEditPart()),
  editPrintArea: (printAreaId: string) => dispatch(editPrintArea(printAreaId)),
  updatePrintArea: (id: string, view: number, params: any) => dispatch(updatePrintArea(id, view, params)),
  updatePartPosition: (id: string, position: any) => dispatch(updatePartPosition(id, position)),
  scalePartFontSize: (id: string, scale: number) => dispatch(scalePartFontSize(id, scale)),
  setForceHighlight: (id: string) => dispatch(forceHighlight([id])),
  nextView: () => dispatch(nextView()),
  previousView: () => dispatch(previousView()),
  zoomOut: () => dispatch(setZoom(0)),
  zoomIn: () => dispatch(setZoom(1)),
  onDisplayerResize: (size: { width: number; height: number }) => dispatch(setDisplayerSize(size)),
})

export type ConnectedDisplayerContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export default connect(mapStateToProps, mapDispatchToProps)

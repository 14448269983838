import React from 'react'

import { Button, Modal, ModalProps } from 'common/components'
import CheckList from 'common/components/CheckList'

const enterprisePlanAdvantages = [
  'Volume discounts',
  'Custom eCommerce integration',
  'Dedicated priority support',
  'Generate quotes and sell offline',
]

export interface EntreprisePlanModalProps extends Omit<ModalProps, 'children'> {
  onClose: () => void
}

const EntreprisePlanModal = ({ onClose, ...modalProps }: EntreprisePlanModalProps) => {
  return (
    <Modal {...modalProps}>
      <Modal.CloseButton onClick={onClose} />
      <Modal.Title>Entreprise plan</Modal.Title>
      <Modal.Details className="space-y-4 font-p1">
        <span>Available to businesses with large payments volumes, high value transactions.</span>
        <div>
          <CheckList items={enterprisePlanAdvantages} />
        </div>
      </Modal.Details>
      <Modal.Actions>
        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => window.open('https://gokickflip.com/get-started/', '_blank')}>
          Contact sales
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default EntreprisePlanModal

import classNames from 'classnames'
import React, { useEffect } from 'react'
import tinycolor from 'tinycolor2'

import { useThemeSettings } from 'customizer/theme'
import Magician from 'icons/illustrations/magician-male.svg'

import './EmptyProduct.scss'

const sheet = document.createElement('style')
document.body.appendChild(sheet)

const LUMINANCE_THRESHOLD = 0.075

const updateMagicianClasses = (backgroundColor: string, invert: boolean) => {
  sheet.innerHTML = `
  .magician-male_svg__cls-1 {
    fill: ${invert ? tinycolor(backgroundColor).brighten(10) : tinycolor(backgroundColor).darken(5)};
  }
  .magician-male_svg__cls-6 {
    fill: ${tinycolor(backgroundColor).brighten(30)};
  }
  .magician-male_svg__cls-2,
  .magician-male_svg__cls-3,
  .magician-male_svg__cls-4,
  .magician-male_svg__cls-7,
  .magician-male_svg__cls-8 {
    stroke: ${invert ? tinycolor(backgroundColor).brighten(3) : tinycolor(backgroundColor).darken(12)}
  }
  .magician-male_svg__cls-5 {
    fill: ${invert ? tinycolor(backgroundColor).brighten(5) : tinycolor(backgroundColor).darken(12)}
  }
  .magician-male_svg__cls-6, .magician-male_svg__cls-4, .magician-male_svg__cls-8 {
    fill: ${tinycolor(backgroundColor).brighten(20)};
  }
  .magician-male_svg__cls-3, .magician-male_svg__cls-7 {
    fill: ${invert ? tinycolor(backgroundColor).brighten(60) : tinycolor(backgroundColor).brighten(60)};
  }
  `
}

interface EmptyProductProps {
  onLoadingCompleted: () => void
}

const EmptyProduct = ({ onLoadingCompleted }: EmptyProductProps) => {
  const { backgroundColor = '#FFF' } = useThemeSettings('customizer')

  const invert = tinycolor(backgroundColor).getLuminance() < LUMINANCE_THRESHOLD

  const messageClassNames = classNames('empty-product__message', {
    'empty-product__message--invert': invert,
  })

  useEffect(() => {
    updateMagicianClasses(backgroundColor, invert)
  }, [backgroundColor, invert])

  useEffect(() => {
    onLoadingCompleted()
  }, [])

  return (
    <div className="empty-product">
      <div className="empty-product__illustration">
        <Magician />
      </div>
      <div className={messageClassNames}>Your product will appear here</div>
    </div>
  )
}

export default EmptyProduct

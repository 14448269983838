import { DenormalizedQuote, Design } from '@packages/types'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import React from 'react'

import { NEUTRAL_400 } from './assets'
import PrintableQuoteTable from './PrintableQuoteTable'

interface PrintableQuotePriceDetailsProps {
  design: Design
  quote: DenormalizedQuote
}

const styles = StyleSheet.create({
  section: {
    paddingTop: 24,
    paddingLeft: 40,
    paddingRight: 40,
  },
  headerTitle: {
    color: NEUTRAL_400,
  },
})

const PrintableQuotePriceDetails = ({ design, quote }: PrintableQuotePriceDetailsProps) => {
  return (
    <View style={styles.section}>
      <View>
        <Text style={styles.headerTitle}>Price details</Text>
      </View>
      <PrintableQuoteTable quote={quote} design={design} />
    </View>
  )
}

export default PrintableQuotePriceDetails

import { createSelector } from 'reselect'

import { Plugin, State } from './types'

export const pluginSelector = (state: State, { id }: { id: string }) => {
  return state.plugins.filter(plugin => plugin.id == id)[0]
}

export const pluginSelectorByLocation = (state: State, location: string) => {
  return state.plugins.find(plugin => plugin.location === location)
}

export const pluginTranslationsSelector = createSelector(
  pluginSelector,
  (_state: State, props: { lang?: string }) => props,
  (plugin, { lang }) => {
    return Object.assign({}, plugin.texts, lang ? plugin.translations[lang] : {})
  }
)

export const pluginTranslationSelector = createSelector(
  pluginSelector,
  (_state: State, props: { lang: string; field: string }) => props,
  (plugin: Plugin, { lang, field }: { lang?: string; field: string }) => {
    const translations = Object.assign({}, plugin.texts, lang ? plugin.translations[lang] : {})

    if (!translations?.[field]) return null

    return translations[field].text || translations[field].from
  }
)

import { Placement } from '@floating-ui/react'
import classNames from 'classnames'
import React from 'react'

import { SanitizedHtml } from 'common/components'
import { useThemeSettings } from 'customizer/theme'
import OutOfStockIcon from 'icons/regular/01-Interface Essential/23-Delete/delete.svg'
import { Popover, usePopover } from 'themes/common/components'
import { isIOS } from 'utils/isMobileDevice'

import './DescriptionTooltip.scss'
import Translation from '../Translation'

interface TooltipStyle {
  tooltipBackgroundColor: string
  tooltipRoundings: string
  answerFontColor: string
  answerFontSize: string
  answerFontFamily?: { fontFamily: string }
  descriptionTextColor: string
  descriptionFontSize: string
  descriptionFontFamily?: { fontFamily: string }
}

interface TooltipProps {
  children: React.ReactElement<any>
  style: TooltipStyle & { linkColor: string }
  description?: string | null
  header?: string | null
  placement?: Placement
  onClick?: () => void
  isOutOfStock?: boolean
  allowHeaderOnly?: boolean
}

const Tooltip = ({
  header = null,
  description = null,
  children,
  style,
  placement = 'bottom',
  onClick,
  isOutOfStock,
}: TooltipProps) => {
  const isDeviceIOS = isIOS()
  const popover = usePopover({ placement, trigger: 'hover', offsetConfig: 12 })

  const handleOnClick = () => {
    if (isDeviceIOS && onClick) onClick()
    popover.close()
  }

  return (
    <>
      <div {...popover.referenceProps} onClick={() => popover.close()}>
        {children}
      </div>
      <Popover
        isOpen={popover.isOpen}
        {...popover.floatingProps}
        onClick={handleOnClick}
        className="description-popover-content"
        contentStyle={{
          borderRadius: style.tooltipRoundings,
        }}
      >
        <div
          data-animation="shift-toward"
          style={{
            backgroundColor: style.tooltipBackgroundColor,
          }}
          className="description-tooltip"
          tabIndex={-1}
        >
          <div
            className={classNames('description-tooltip__header', {
              'description-tooltip__header--no-padding-bottom': (!description && !isOutOfStock) || !header,
            })}
          >
            <span
              style={{
                color: style.answerFontColor,
                fontSize: style.answerFontSize,
                fontFamily: style.answerFontFamily?.fontFamily,
              }}
            >
              {header}
            </span>
          </div>
          {isOutOfStock && (
            <span
              className={classNames('description-tooltip__out-of-stock', { '--no-padding-bottom': !description })}
              style={{
                color: style.descriptionTextColor,
                fontFamily: style.descriptionFontFamily?.fontFamily,
                fontSize: style.descriptionFontSize,
              }}
            >
              <OutOfStockIcon className="description-tooltip__out-of-stock__icon" />
              <span>
                <Translation field="outOfStock" />
              </span>
            </span>
          )}
          <span
            className="description-tooltip__description"
            style={{
              color: style.descriptionTextColor,
              fontFamily: style.descriptionFontFamily?.fontFamily,
              fontSize: style.descriptionFontSize,
            }}
          >
            {description && <SanitizedHtml dirtyHtml={description} tagsStyle={{ a: `color: ${style.linkColor};` }} />}
          </span>
        </div>
      </Popover>
    </>
  )
}

Tooltip.displayName = 'Tooltip'

export interface DescriptionTooltipProps {
  children: React.ReactElement<any>
  description?: string | null
  header?: string | null
  style: TooltipStyle
  allowHeaderOnly?: boolean
  showDescription?: boolean
  placement?: Placement
  onClick?: () => void
  isOutOfStock?: boolean
}

const DescriptionTooltip = ({
  children,
  description,
  showDescription,
  header,
  style,
  allowHeaderOnly = true,
  placement,
  onClick,
  isOutOfStock,
}: DescriptionTooltipProps) => {
  const { linkColor } = useThemeSettings('questionPanel')
  const tooltipDescription = showDescription ? description : null

  return (!allowHeaderOnly || !header) && !tooltipDescription && !isOutOfStock ? (
    children
  ) : (
    <Tooltip
      description={tooltipDescription}
      header={header}
      style={{ ...style, linkColor }}
      placement={placement}
      onClick={onClick}
      isOutOfStock={isOutOfStock}
    >
      {children}
    </Tooltip>
  )
}

DescriptionTooltip.displayName = 'DescriptionTooltip'

export default DescriptionTooltip

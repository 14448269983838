import config from './config.json'

const getCurrentDateVersion = (): string => {
  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0')
  const day = String(today.getDate()).padStart(2, '0')

  return `${year}${month}${day}`
}

const injectMigrationScript = () => {
  try {
    const env = (process.env.NODE_ENV as keyof typeof config.baseUrlV1) || 'default'
    const baseUrl = config.baseUrlV1[env] ?? config.baseUrlV1.default
    const scriptSourceUrl = `${baseUrl}${config.pathScriptMigration}`
    const jsVersion = getCurrentDateVersion()

    const scriptTag = document.createElement('script')
    scriptTag.src = `${scriptSourceUrl}?v=${jsVersion}`
    scriptTag.async = true
    document.body.appendChild(scriptTag)
  } catch (error) {
    console.error('Error injecting migration script!')
  }
}

export default injectMigrationScript

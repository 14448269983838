import versionSort from 'utils/versionSort'

import { Plugin, RegistryPlugin, PluginUpdateFunction } from './types'

const BASE_VERSION = '1.0.0'

export default (updateFunctions: Record<string, PluginUpdateFunction>) => {
  const orderedVersions = Object.keys(updateFunctions).sort(versionSort)

  return (defaultPlugin: RegistryPlugin, currentPlugin: Plugin) => {
    const updatedPlugin = JSON.parse(JSON.stringify(currentPlugin))

    if (!updatedPlugin.version) updatedPlugin.version = BASE_VERSION

    orderedVersions.reduce((shouldUpdate, version) => {
      if (shouldUpdate) updateFunctions[version](defaultPlugin, updatedPlugin)
      return shouldUpdate || version == updatedPlugin.version
    }, false)

    updatedPlugin.version = defaultPlugin.version

    return updatedPlugin as Plugin
  }
}

import React from 'react'
import { useSelector } from 'react-redux'

import { TranslationComponent } from 'themes/common/components'

import { pluginTranslationsSelector } from '../selectors'
import { PluginTranslations, State } from '../types'

interface PluginTranslationProps {
  field: string
  className: string
  pluginId: string
}

const PluginTranslation = ({ field, className, pluginId }: PluginTranslationProps) => {
  const translations = useSelector<State, PluginTranslations>(state =>
    pluginTranslationsSelector(state, { id: pluginId, lang: state.lang })
  )

  return <TranslationComponent translations={translations} field={field} className={className} />
}

export default PluginTranslation

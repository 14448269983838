import { Color } from '@react-stately/color'
import React from 'react'

import { ColorSlider, ColorTextInput } from 'themes/common/components'
import * as validators from 'utils/validators'

import './RGBColorSliders.scss'

const rgbChannelValidator = validators.combine(validators.min(0), validators.max(255))

interface RGBColorSidersProps {
  color: Color
  onChange: (color: Color) => void
  onChangeEnd: (color: Color) => void
}

const RGBColorSliders = ({ color, onChange, onChangeEnd }: RGBColorSidersProps) => {
  const rgbColor = color.toFormat('rgb')

  return (
    <div className="rgb-color-slider">
      <div className="rgb-color-slider__row">
        <div className="rgb-color-slider__input">
          <ColorTextInput
            inputAdornment="R"
            type="number"
            className="interior-color-input"
            value={String(rgbColor.getChannelValue('red'))}
            onBlur={value => {
              const newColor = rgbColor.withChannelValue('red', value as number).toFormat('hsb')
              onChange(newColor)
              onChangeEnd(newColor)
            }}
            validator={rgbChannelValidator}
          />
        </div>
        <div className="rgb-color-slider__slider">
          <ColorSlider
            channel="red"
            color={rgbColor}
            onChange={c => {
              onChange(rgbColor.withChannelValue('red', c.getChannelValue('red')).toFormat('hsb'))
            }}
            onChangeEnd={onChangeEnd}
          />
        </div>
      </div>

      <div className="rgb-color-slider__row">
        <div className="rgb-color-slider__input">
          <ColorTextInput
            inputAdornment="G"
            type="number"
            className="interior-color-input"
            value={String(rgbColor.getChannelValue('green'))}
            onBlur={value => {
              const newColor = rgbColor.withChannelValue('green', value as number).toFormat('hsb')
              onChange(newColor)
              onChangeEnd(newColor)
            }}
            validator={rgbChannelValidator}
          />
        </div>
        <div className="rgb-color-slider__slider">
          <ColorSlider
            channel="green"
            color={rgbColor}
            onChange={c => {
              onChange(rgbColor.withChannelValue('green', c.getChannelValue('green')).toFormat('hsb'))
            }}
            onChangeEnd={onChangeEnd}
          />
        </div>
      </div>

      <div className="rgb-color-slider__row">
        <div className="rgb-color-slider__input">
          <ColorTextInput
            inputAdornment="B"
            type="number"
            className="interior-color-input"
            value={String(rgbColor.getChannelValue('blue'))}
            onBlur={value => {
              const newColor = rgbColor.withChannelValue('blue', value as number).toFormat('hsb')
              onChange(newColor)
              onChangeEnd(newColor)
            }}
            validator={rgbChannelValidator}
          />
        </div>
        <div className="rgb-color-slider__slider">
          <ColorSlider
            channel="blue"
            color={rgbColor}
            onChange={c => {
              onChange(rgbColor.withChannelValue('blue', c.getChannelValue('blue')).toFormat('hsb'))
            }}
            onChangeEnd={onChangeEnd}
          />
        </div>
      </div>
    </div>
  )
}

export default RGBColorSliders

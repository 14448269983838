import classNames from 'classnames'
import React from 'react'

import { Button, Popover, usePopover } from 'common/components'
import CheckCircleIcon from 'icons/bold/01-Interface Essential/33-Form-Validation/check-circle-1.svg'
import SkateIcon from 'icons/custom/bold-skateboard.svg'
import HelpIcon from 'icons/regular/01-Interface Essential/14-Alerts/question-circle.svg'

export interface CreateProductPopoverProps {
  onCreateProduct: () => void
  isCompleted: boolean
}

const CreateProductPopover = ({ onCreateProduct, isCompleted }: CreateProductPopoverProps) => {
  const popover = usePopover({ placement: 'right', offsetConfig: 32, useArrow: true })

  return (
    <>
      <button
        {...popover.referenceProps}
        className={classNames('font-p2 flex items-center justify-between h-4', {
          'text-tertiary-green-300': isCompleted,
          'text-primary-600 font-bold': popover.isOpen,
        })}
        disabled={isCompleted}
      >
        3. Create your first product
        {isCompleted && <CheckCircleIcon className="w-4 h-4 fill-tertiary-green-300" aria-label="completed" />}
      </button>

      <Popover {...popover.floatingProps} isOpen={popover.isOpen} className="!w-80 shadow-lg">
        <div>
          <div className="flex flex-col p-4 space-y-4 text-xs font-medium text-neutral-600">
            <div className="w-[45px] h-[45px] rounded-lg bg-neutral-75 p-2">
              <SkateIcon />
            </div>
            <div className="flex">
              <div className="flex flex-col">
                <span className="flex-1 mr-2">1.</span>
                <span>2.</span>
              </div>

              <div className="flex flex-col">
                <span className="flex-1 mb-2">Create your customizer by adding images, texts, logos and more</span>
                <span>Press Publish!!!</span>
              </div>
            </div>
          </div>

          <div className="px-4 py-3 flex justify-between border-t border-t-neutral-75">
            <a
              href="https://help.gokickflip.com/en/articles/4580444-start-building-your-customizer"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                type="button"
                variant="default"
                className="px-4"
                icon={<HelpIcon className="w-4 h-4" />}
                iconPosition="right"
              >
                Learn more
              </Button>
            </a>
            <Button type="button" variant="primary" className="px-4" onClick={() => onCreateProduct()}>
              Create product
            </Button>
          </div>
        </div>
      </Popover>
    </>
  )
}

export default CreateProductPopover

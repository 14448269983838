import { Answer, AnswerType, MaterialView } from '@packages/types'
import classNames from 'classnames'
import * as React from 'react'

import defaultThumbnailImg from 'img/variation.png'

import SVGThumbnail from './SVGThumbnail'
import withAnswerTheme, { WithAnswerThemeProps } from './withAnswerTheme'

export type SVGAnswerProps = Answer & WithAnswerThemeProps

const SVGAnswer = withAnswerTheme<SVGAnswerProps>(({ views, type, id, thumbnail, extraClassNames, style }) => {
  let content = null
  switch (type) {
    case AnswerType.Material: {
      const view = (views as MaterialView[]).find(({ texture }) => texture != null) || (views as MaterialView[])[0]
      content = <SVGThumbnail id={id} color={view?.color} image={view?.texture} />
      break
    }
    case AnswerType.Font:
    case AnswerType.FontSize:
    case AnswerType.Value: {
      content = <SVGThumbnail id={id} image={thumbnail ?? { url: defaultThumbnailImg }} />
      break
    }
  }

  return (
    <div className={classNames('image-choice', extraClassNames)} style={{ ...style }} data-testid="svg-answer">
      {content}
    </div>
  )
})

export default SVGAnswer

import { CustomizerProductSubmitAction } from '@packages/types'
import React, { useContext } from 'react'
import { styled } from 'styled-components'

import { twoDDisplayerSelectors } from 'customizer/2dDisplayer'
import { selectors as customizationSelectors } from 'customizer/customization'
import { useDispatch, useSelector } from 'customizer/hooks'
import { actions as questionPanelActions } from 'customizer/questionPanel'
import { setShowFinishCustomization } from 'customizer/questionPanel/actions'
import { isSubmittingSelector } from 'customizer/questionPanel/selectors'
import { actions as quoteActions } from 'customizer/quote'
import { selectors as stocksSelectors } from 'customizer/stocks'
import { useThemeSettings } from 'customizer/theme'
import Loading from 'icons/custom/loading.svg'
import barebonesTheme from 'themes/barebones/config.json'
import booklikeTheme from 'themes/booklike/config.json'
import { selectInvalidStep } from 'themes/common/questionPanel/actions'

import { useValidateCustomization } from '../hooks'
import CircularLoadingIcon from './CircularLoadingIcon'
import { SummaryDrawerContext } from './summaryDrawer'
import Translation from './Translation'

interface StyledActionSection {
  $styled: Record<keyof (typeof barebonesTheme & typeof booklikeTheme)['settings']['actionSection']['settings'], any>
  style: React.CSSProperties
  isTouch?: boolean
}

const StyledButton = styled.button<StyledActionSection>`
  &&& {
    font-family: ${props => props.$styled.fontFamily?.fontFamily};
    color: ${props => props.$styled.color};
    background-color: ${props => props.$styled.backgroundColor};
    font-size: ${props => props.$styled.fontSize};
    border: ${props => props.style.borderWidth || props.$styled.buttonsBorderWidth} solid
      ${props => props.style.borderColor || props.$styled.buttonsBorderColor};
    border-radius: ${props => props.style.borderRadius || props.$styled.buttonsBorderRadius || ''};

    &:hover {
      background-color: ${props => (!props.isTouch ? props.$styled.hoverBackgroundColor : '')};
    }

    &:disabled {
      opacity: 0.4;
      cursor: default;
      background-color: ${props => props.$styled.backgroundColor};
    }
  }
`

const StyledCircle = styled.circle<StyledActionSection>`
  &&& {
    stroke: ${props => props.$styled.color};
    fill: ${props => props.$styled.backgroundColor};
    ${StyledButton}:hover & {
      fill: ${props => (!props.isTouch ? props.$styled.hoverBackgroundColor : '')};
    }
  }
`

export const ariaLabelForSubmitAction = {
  [CustomizerProductSubmitAction.AddToCart]: 'Add to cart',
  [CustomizerProductSubmitAction.GetAQuote]: 'Get a quote',
}

export interface SubmitActionProps {
  id?: string
  className?: string
  loading: 'circularCircle' | 'pulsingBar'
  autoResize?: boolean
  showOutOfStockMessageCard: boolean
  setShowOutOfStockMessageCard: (show: boolean) => void
  setShowOutOfStockMessageCardWithTimeout?: () => void
  style?: React.CSSProperties
  showSummary?: boolean
}

const SubmitAction = ({
  id,
  className,
  loading,
  autoResize,
  showOutOfStockMessageCard,
  setShowOutOfStockMessageCard,
  setShowOutOfStockMessageCardWithTimeout,
  style = {},
  showSummary,
}: SubmitActionProps) => {
  const { drawer } = useContext(SummaryDrawerContext)

  const dispatch = useDispatch()
  const validateCustomization = useValidateCustomization()
  const isMobile = useSelector(twoDDisplayerSelectors.isMobileSelector)
  const isTouch = useSelector(twoDDisplayerSelectors.isTouchSelector)
  const isSubmitting = useSelector(isSubmittingSelector)
  const submitAction = useSelector(customizationSelectors.submitActionSelector)
  const isSelectionOutOfStock = useSelector(stocksSelectors.isSelectionOutOfStockSelector)
  const defaultStyle = useThemeSettings('actionSection')

  const handleClick = () => {
    if (isSubmitting) return

    const { invalidSteps, isValid } = validateCustomization()

    if (isValid) {
      if (submitAction === CustomizerProductSubmitAction.GetAQuote) return dispatch(quoteActions.openForm())
      return dispatch(questionPanelActions.addToCart())
    }

    if (isMobile) dispatch(setShowFinishCustomization(false))

    if (showSummary) return drawer.snap(1)
    if (invalidSteps.length > 0) return dispatch(selectInvalidStep(invalidSteps))
    if (isMobile && isSelectionOutOfStock) return setShowOutOfStockMessageCardWithTimeout?.()
  }

  return (
    <StyledButton
      id={id}
      className={className}
      aria-label={ariaLabelForSubmitAction[submitAction]}
      onClick={handleClick}
      $styled={defaultStyle}
      style={style}
      isTouch={isTouch ? isTouch : undefined}
      onPointerEnter={() => !isMobile && !showOutOfStockMessageCard && setShowOutOfStockMessageCard(true)}
      onPointerLeave={() => !isMobile && setShowOutOfStockMessageCard(false)}
    >
      {isSubmitting && (
        <>
          {loading === 'circularCircle' ? (
            <CircularLoadingIcon
              style={{ ...defaultStyle, ...style }}
              isTouch={isTouch ? isTouch : undefined}
              StyledCircle={StyledCircle}
            />
          ) : (
            <Loading style={{ width: '55px', color: style.color, height: '100%' }} />
          )}
        </>
      )}
      {(!isSubmitting || autoResize) && (
        <Translation
          style={{ visibility: isSubmitting ? 'hidden' : 'unset' }}
          field={submitAction === CustomizerProductSubmitAction.GetAQuote ? 'getAQuote' : 'finalisation'}
        />
      )}
    </StyledButton>
  )
}

export default SubmitAction

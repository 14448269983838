import { StripeSubscription, SubscriptionStatus, Plan, Addon } from '@packages/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isEqual } from 'lodash'
import React from 'react'

import { useSubscriptionService } from 'cms/subscription/hooks'
import { Button, Card, useToast } from 'common/components'
import { ToastType } from 'common/components/toast/types'

import TotalCardContent from './TotalCardContent'

export interface StripeTotalCardProps {
  plan: Plan
  addons: Addon[]
  subscription: StripeSubscription
  onContinue: () => void
  onChangePlan: () => void
}

const StripeTotalCard = ({ onContinue, onChangePlan, addons, subscription, plan }: StripeTotalCardProps) => {
  const subscriptionService = useSubscriptionService()
  const queryClient = useQueryClient()
  const { openGenericErrorToast, openToast } = useToast()

  const { mutate: changePlan, isLoading } = useMutation(() => subscriptionService.changePlan({ plan, addons }), {
    onSuccess: () => {
      openToast('Your plan was successfully changed.', ToastType.success)
      queryClient.invalidateQueries(subscriptionService.fetchOne.queryKeys)
      onChangePlan()
    },
    onError: () => {
      openGenericErrorToast('Your plan was not changed.')
    },
  })

  const activated =
    subscription.status === SubscriptionStatus.Active ||
    (subscription.status === SubscriptionStatus.FreeTrial && subscription.stripePaymentMethodId)
  const dirty = !isEqual(subscription.plan, plan) || !isEqual(subscription.addons, addons)

  return (
    <Card className="flex-col items-center w-[310px]">
      <Card.Section className="md:flex-col space-y-6">
        <TotalCardContent addons={addons} plan={plan} />
      </Card.Section>
      <Card.Footer className="w-full pt-3 pb-4">
        {activated ? (
          <Button
            disabled={!dirty || isLoading}
            variant="primary"
            className="w-full"
            isLoading={isLoading}
            onClick={() => changePlan()}
          >
            Change plan
          </Button>
        ) : (
          <Button variant="primary" className="w-full" onClick={onContinue}>
            Continue
          </Button>
        )}
      </Card.Footer>
    </Card>
  )
}

export default StripeTotalCard

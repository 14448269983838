import { DataTablePersistence } from './persistence'

type TableDateFiltersPersistence = { startDate?: Date | null; endDate?: Date | null }

type TableDateFiltersConfig = { onChange?: () => void }

const useTableDateFilters =
  <TModel extends TableDateFiltersPersistence = any>({ onChange }: TableDateFiltersConfig) =>
  ({ state, setState }: DataTablePersistence<TModel>) => {
    const setStartDate = (startDate?: Date | null) => {
      setState(state => ({ ...state, startDate }))

      if (state.endDate) {
        onChange?.()
      }
    }

    const setEndDate = (endDate?: Date | null) => {
      setState(state => ({ ...state, endDate }))

      if (state.startDate) {
        onChange?.()
      }
    }

    return {
      startDate: state.startDate,
      endDate: state.endDate,
      setStartDate,
      setEndDate,
    }
  }

export default useTableDateFilters

import classNames from 'classnames'
import React, { useState } from 'react'

import { useDispatch } from 'cms/hooks'
import { useThemeSettings } from 'customizer/theme'
import ShareIcon from 'icons/custom/share-icon.svg'
import { Portal } from 'plugins/components'
import { Translation, CircularLoadingIcon } from 'themes/common/components'

import { PluginComponent } from '../../types'
import { shareDesign } from '../actions'

import './ShareButton.css'

const ShareButton: PluginComponent = () => {
  const dispatch = useDispatch()
  const shareIcon = useThemeSettings('shareIcon')
  const [isGeneratingDesign, setIsGeneratingDesign] = useState(false)
  const portalClassNames = classNames('share-button__portal', {
    'share-button__portal--disabled': !shareIcon.displayShareButton,
  })

  const onSuccessCallBack = () => setIsGeneratingDesign(false)

  const handleOnClick = () => {
    setIsGeneratingDesign(true)
    dispatch(shareDesign(onSuccessCallBack))
  }

  return (
    <Portal target=".choice-panel" componentKey="shareButton" className={portalClassNames}>
      {isGeneratingDesign ? (
        <div className="share-button__circular-loading-icon">
          <CircularLoadingIcon style={{ color: shareIcon.color }} />
        </div>
      ) : (
        <div role="button" className="socials-share-button" aria-label="Save design">
          {shareIcon.radioShareType === 'text' ? (
            <Translation
              onClick={handleOnClick}
              className="social-share"
              style={{ color: shareIcon.color }}
              field="socialShare"
            />
          ) : (
            <ShareIcon
              className="icon share"
              style={{ fill: shareIcon.color }}
              onClick={handleOnClick}
              aria-label="Save design icon"
            />
          )}
        </div>
      )}
    </Portal>
  )
}

ShareButton.pluginMetadata = {
  // required
  name: 'Pure Hockey Share Button',
  key: 'shareButton',
}

export default ShareButton

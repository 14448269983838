import { Asset, Brand, supportedImageExtensions } from '@packages/types'
import { UseMutateFunction } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React from 'react'

import { NetworkError } from 'common/api'
import { FileUploader } from 'common/assets/components'
import { Button, Card, WithInformation } from 'common/components'
import { withFlag } from 'common/users/components'

import { BrandUpdateQuery } from '../types'

interface BrandLogoUploaderProps {
  brandLogo?: Asset
  isSaving?: boolean
  onSubmit: UseMutateFunction<Brand, NetworkError, BrandUpdateQuery>
}

const BrandLogoUploader = ({ brandLogo, isSaving, onSubmit }: BrandLogoUploaderProps) => {
  const formik = useFormik<{ logo?: Asset }>({
    initialValues: { logo: brandLogo },
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => onSubmit({ logo: values.logo }, { onSettled: () => setSubmitting(false) }),
  })

  return (
    <Card className="flex flex-1 flex-col">
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className="p-6">
          <WithInformation tooltip="We recommend a square logo with an ideal size of 500x500 pixels.">
            <label htmlFor="brandLogo">Logo</label>
          </WithInformation>
          <div className="mt-3">
            <FileUploader
              dropText="Drop file to attach, or"
              file={formik.values.logo}
              extensions={supportedImageExtensions}
              onChange={file => formik.setFieldValue('logo', file)}
            />
          </div>
        </div>
        <Card.Footer>
          <Button type="reset" onClick={() => formik.resetForm()} disabled={!formik.dirty || isSaving}>
            Discard
          </Button>
          <Button variant="primary" disabled={!formik.dirty || isSaving} isLoading={isSaving}>
            Save
          </Button>
        </Card.Footer>
      </form>
    </Card>
  )
}

export default withFlag({
  Component: BrandLogoUploader,
  feature: 'quote_phase_1',
})

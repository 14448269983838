import Konva from 'konva'

import { doPolygonsIntersect, toRads, rotatePoint, Polygon } from 'utils/math'

import { ClippingGroup } from '../types'
import GroupClientRectClip from './GroupClientRectClip'
import ViewContainer from './ViewContainer'

type TransformerEndCallback = (params: {
  x: number
  y: number
  width: number
  height: number
  rotation: number
  scale: Konva.Vector2d
}) => void

class PrintArea extends Konva.Group {
  static defaultColor = '#0021f5'

  printArea?: Konva.Shape
  clippingGroup?: GroupClientRectClip
  transformer?: Konva.Transformer
  bleed?: Konva.Shape
  bleedText?: Konva.Text
  margin?: Konva.Shape
  marginText?: Konva.Text
  marginTextLine?: Konva.Line
  params?: ClippingGroup
  outlineColor?: string
  transformEndCallback?: TransformerEndCallback
  stopEditingCallback?: () => void

  getScaledParams(params: ClippingGroup) {
    return {
      ...params,
      x: params.x * params.scale,
      y: params.y * params.scale,
      width: params.width * params.scale,
      height: params.height * params.scale,
      margins: {
        horizontal: params.margins.horizontal * params.scale,
        vertical: params.margins.vertical * params.scale,
      },
      bleed: params.bleed * params.scale,
    }
  }

  getFontSize() {
    return Math.round(15 / this.getAbsoluteScale().x)
  }

  renderPrintAreaBleed(params: ClippingGroup) {
    const scaledParams = this.getScaledParams(params)

    this.bleed = super.findOne('#bounding-box-bleed')
    this.bleedText = super.findOne('#bounding-box-bleed-text')

    if (scaledParams.bleed > 0) {
      if (!this.bleed) {
        this.bleed = new Konva.Rect({
          strokeWidth: 1,
          strokeScaleEnabled: false,
        })

        this.bleedText = new Konva.Text({
          text: 'Bleed',
          fontSize: this.getFontSize(),
        })

        if (this.transformer) this.bleedText.hide()
        super.add(this.bleedText)
        super.add(this.bleed)
      }

      this.bleed.id('bounding-box-bleed')
      this.bleed.width(scaledParams.width + 2 * scaledParams.bleed)
      this.bleed.height(scaledParams.height + 2 * scaledParams.bleed)
      this.bleed.stroke(scaledParams.outlineColor)
      this.bleed.dash([10, 5])

      this.bleedText.fontSize(this.getFontSize())
      this.bleedText.offsetY(this.getFontSize() + 2)

      this.bleedText.id('bounding-box-bleed-text')
      this.bleedText.fill(scaledParams.outlineColor)
    } else {
      this.bleed?.remove()
      this.bleedText?.remove()
    }
  }

  getViewContainer() {
    return (this.getAncestors() as ViewContainer[]).find(node => node.isViewContainer)
  }

  renderPrintAreaMargin(params: ClippingGroup) {
    const scaledParams = this.getScaledParams(params)
    this.margin = super.findOne('#bounding-box-margin')
    this.marginText = super.findOne('#bounding-box-margin-text')
    this.marginTextLine = super.findOne('#bounding-box-margin-text-line')

    if (scaledParams.margins.horizontal > 0 || scaledParams.margins.vertical > 0) {
      if (!this.margin) {
        this.margin = new Konva.Rect({
          listening: false,
          strokeWidth: 1,
          strokeScaleEnabled: false,
        })
        this.marginText = new Konva.Text({
          text: 'Safe area',
        })
        this.marginTextLine = new Konva.Line({
          strokeWidth: 1,
          strokeScaleEnabled: false,
        })
        super.add(this.margin)

        if (this.transformer) {
          this.marginText.hide()
          this.marginTextLine.hide()
        }
        super.add(this.marginText)
        super.add(this.marginTextLine)
      }

      this.margin.id('bounding-box-margin')
      this.margin.offsetX(-scaledParams.bleed - scaledParams.margins.horizontal)
      this.margin.offsetY(-scaledParams.bleed - scaledParams.margins.vertical)
      this.margin.width(scaledParams.width - 2 * scaledParams.margins.horizontal)
      this.margin.height(scaledParams.height - 2 * scaledParams.margins.vertical)
      this.margin.stroke(scaledParams.outlineColor)
      this.margin.dash([10, 5])

      this.marginTextLine.id('bounding-box-margin-text-line')
      this.marginTextLine.setAttrs({
        points: [0, 0, 0, scaledParams.bleed + scaledParams.margins.vertical + 45],
        offsetX: -(2 * scaledParams.bleed + scaledParams.width) / 2,
        offsetY: -(scaledParams.bleed + scaledParams.height - scaledParams.margins.vertical),
        stroke: scaledParams.outlineColor,
        dash: [10, 5],
      })
      this.marginText.id('bounding-box-margin-text')
      this.marginText.offsetY(-(2 * scaledParams.bleed + scaledParams.height + 57))
      this.marginText.fontSize(this.getFontSize())
      this.marginText.width(2 * scaledParams.bleed + scaledParams.width)
      this.marginText.align('center')
      this.marginText.fill(scaledParams.outlineColor)
    } else {
      this.margin?.remove()
      this.marginText?.remove()
      this.marginTextLine?.remove()
    }
  }

  render(params: ClippingGroup) {
    const scaledParams = this.getScaledParams(params)

    this.x(scaledParams.x)
    this.y(scaledParams.y)
    this.rotation(scaledParams.rotation)

    this.renderClippingGroup(params)

    if (scaledParams.showPrintArea || scaledParams.editable) {
      this.renderPrintArea(params)
    } else {
      this.destroyPrintArea()
    }
  }

  renderPrintArea(params: ClippingGroup) {
    const scaledParams = this.getScaledParams(params)

    this.renderPrintAreaMargin(params)

    this.printArea = super.findOne('#bounding-box')

    if (!this.printArea) {
      this.printArea = new Konva.Rect({
        listening: false,
        strokeScaleEnabled: false,
        strokeWidth: 1,
      })
      super.add(this.printArea)
    }

    this.renderPrintAreaBleed(params)

    this.outlineColor = scaledParams.outlineColor

    this.printArea.id('bounding-box')
    this.printArea.width(scaledParams.width)
    this.printArea.height(scaledParams.height)
    this.printArea.stroke(scaledParams.outlineColor)

    this.printArea.x(scaledParams.bleed)
    this.printArea.y(scaledParams.bleed)

    this.params = scaledParams

    this.moveToTop()
  }

  renderClippingGroup(params: ClippingGroup) {
    const scaledParams = this.getScaledParams(params)

    this.clippingGroup = super.findOne(`#clipping-group-${scaledParams.id}`)
    if (!this.clippingGroup) {
      this.clippingGroup = new GroupClientRectClip()
      this.clippingGroup.id(`clipping-group-${scaledParams.id}`)
      super.add(this.clippingGroup)
    }

    this.clippingGroup.setAttrs({
      scale: { x: scaledParams.groupScale, y: scaledParams.groupScale },
      clip:
        scaledParams.showPrintArea && !scaledParams.editable
          ? {
              x: 0,
              y: 0,
              width: (scaledParams.width + scaledParams.bleed * 2) / scaledParams.groupScale,
              height: (scaledParams.height + scaledParams.bleed * 2) / scaledParams.groupScale,
            }
          : {
              x: scaledParams.bleed / scaledParams.groupScale,
              y: scaledParams.bleed / scaledParams.groupScale,
              width: scaledParams.width / scaledParams.groupScale,
              height: scaledParams.height / scaledParams.groupScale,
            },
    })

    this.clippingGroup.dragBoundRectangle = {
      width: scaledParams.width / scaledParams.groupScale,
      height: scaledParams.height / scaledParams.groupScale,
      offsetX: scaledParams.bleed / scaledParams.groupScale,
      offsetY: scaledParams.bleed / scaledParams.groupScale,
    }

    return this
  }

  _dragBoundFunc(pos: { x: number; y: number }) {
    const scale = this.getStage()!.scaleX()

    const outerMostElement = this.bleed ? this.bleed : this.printArea

    const width = outerMostElement!.width() * scale
    const height = outerMostElement!.height() * scale

    const parentPos = { x: 0, y: 0 }
    const dragRectPos = {
      x: parentPos.x,
      y: parentPos.y,
    }

    const dragRectWidth = this.getStage()!.width()
    const dragRectHeight = this.getStage()!.height()

    const offsetX = this.offsetX() * scale
    const offsetY = this.offsetY() * scale

    const points = [
      {
        x: pos.x - offsetX,
        y: pos.y - offsetY,
      },
      {
        x: pos.x + width - offsetX,
        y: pos.y - offsetY,
      },
      {
        x: pos.x + width - offsetX,
        y: pos.y + height - offsetY,
      },
      {
        x: pos.x - offsetX,
        y: pos.y + height - offsetY,
      },
    ]

    const rotatedPoints = points.map(p =>
      rotatePoint(p, toRads(this.rotation()), {
        x: pos.x,
        y: pos.y,
      })
    )

    const parentPoints = [
      {
        x: dragRectPos.x,
        y: dragRectPos.y,
      },
      { x: dragRectPos.x + dragRectWidth, y: dragRectPos.y },
      { x: dragRectPos.x + dragRectWidth, y: dragRectPos.y + dragRectHeight },
      { x: dragRectPos.x, y: dragRectPos.y + dragRectHeight },
    ]

    if (!doPolygonsIntersect(parentPoints as Polygon, rotatedPoints as Polygon)) {
      return rotatePoint(
        {
          x: dragRectPos.x - width / 2 + dragRectWidth / 2,
          y: dragRectPos.y - height / 2 + dragRectHeight / 2,
        },
        toRads(this.rotation()),
        {
          x: dragRectPos.x + dragRectWidth / 2,
          y: dragRectPos.y + dragRectHeight / 2,
        }
      )
    }

    return {
      x: pos.x,
      y: pos.y,
    }
  }

  override findOne(selector: string) {
    return this.clippingGroup?.findOne(selector) as any
  }

  override destroy() {
    this.destroyPrintArea()
    return super.destroy()
  }

  destroyPrintArea() {
    this.printArea?.remove()
    this.bleed?.remove()
    this.bleedText?.remove()
    this.margin?.remove()
    this.marginText?.remove()
    this.marginTextLine?.remove()

    this.printArea = undefined
    this.bleed = undefined
    this.bleedText = undefined
    this.margin = undefined
    this.marginText = undefined
    this.marginTextLine = undefined
  }
}

export default PrintArea

import React from 'react'

import { withFlag } from 'common/users/components'
import { twoDDisplayerSelectors } from 'customizer/2dDisplayer'
import { useDispatch, useSelector } from 'customizer/hooks'
import { actions as quoteActions, selectors as quoteSelectors } from 'customizer/quote'
import { useThemeSettings } from 'customizer/theme'
import { Modal } from 'themes/common/components/modal'

import { QuoteBottomDrawer } from '../bottomDrawer'
import QuoteModalContent from './QuoteModalContent'

import './QuoteModal.scss'

const QuoteModal = () => {
  const dispatch = useDispatch()
  const isMobile = useSelector(twoDDisplayerSelectors.isMobileSelector)
  const isFormVisible = useSelector(quoteSelectors.quoteFormVisibilitySelector)
  const modalStyle = useThemeSettings('modal')
  const questionPanelStyle = useThemeSettings('questionPanel')
  const questionStyle = useThemeSettings('question')

  const handleCloseModal = () => dispatch(quoteActions.closeForm())

  if (!isFormVisible) return null

  if (isMobile) {
    return <QuoteBottomDrawer collapsedHeight={172} />
  }

  return (
    <Modal
      onBackdropClick={handleCloseModal}
      className="quote-modal"
      style={{
        borderRadius: modalStyle.rounding,
        backgroundColor: questionPanelStyle.backgroundColor,
        color: questionStyle.questionFontColor,
        fontFamily: questionStyle.questionFontFamily?.fontFamily,
      }}
    >
      <QuoteModalContent />
    </Modal>
  )
}

export default withFlag({
  Component: QuoteModal,
  feature: 'quote_phase_1',
})

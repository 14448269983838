import { DenormalizedCustomizableQuestion } from '@packages/types'
import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { styled } from 'styled-components'
import tinycolor from 'tinycolor2'

import { customizerProductsUtils } from 'common/customizerProducts'
import { actions as customizationActions, selectors as customizationSelectors } from 'customizer/customization'
import { useDispatch, useSelector } from 'customizer/hooks'
import { selectors as questionPanelSelectors } from 'customizer/questionPanel'
import { useThemeSettings } from 'customizer/theme'
import { selectors as wordFilterSelectors } from 'customizer/wordFilter'
import { validateWordFiltered } from 'customizer/wordFilter/utils'
import { TextInputAndDropdownStyle } from 'themes/common/types'
import areInputCharactersValid from 'utils/areInputCharactersValid'
import isNullOrEmpty from 'utils/isNullOrEmpty'

import './TextQuestion.scss'

interface CursorSelection {
  selectionStart: number
  selectionEnd: number
}

export interface TextQuestionProps {
  question: DenormalizedCustomizableQuestion
  requiredWarning?: boolean
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  createTextAnswer: (id: string, value: string, cleanedValue: string | undefined) => void
  updateTextAnswer: (id: string, selectedAnswerId: string, value: string, cleanedValue: string | undefined) => void
  textInputAndDropdownStyle: TextInputAndDropdownStyle
  className: string
  minRows: number
  maxRows: number
  children: React.ReactNode
}

const TextQuestionInput = styled.input`
  &::-ms-placeholder,
  &::placeholder {
    color: ${({ color }) => tinycolor(color).setAlpha(0.38).toString()};
  }
  &::-webkit-input-placeholder {
    color: ${({ color }) => tinycolor(color).setAlpha(0.38).toString()};
  }
  &::-moz-placeholder {
    color: ${({ color }) => tinycolor(color).setAlpha(0.38).toString()};
  }
`

const TextAreaInput = styled(TextareaAutosize)`
  &::-ms-placeholder,
  &::placeholder {
    color: ${({ color }) => tinycolor(color).setAlpha(0.38).toString()};
  }
  &::-webkit-input-placeholder {
    color: ${({ color }) => tinycolor(color).setAlpha(0.38).toString()};
  }
  &::-moz-placeholder {
    color: ${({ color }) => tinycolor(color).setAlpha(0.38).toString()};
  }
`

const TextQuestion = ({
  question,
  onFocus = () => {},
  updateTextAnswer,
  createTextAnswer,
  textInputAndDropdownStyle,
  className,
  minRows,
  maxRows,
  children,
}: TextQuestionProps) => {
  const {
    id,
    selectedAnswer,
    placeholder,
    maxLength,
    type,
    showCharacterCount,
    allowDecimals = false,
    inputCharacters,
  } = question
  const dispatch = useDispatch()
  const wordFilter = useSelector(wordFilterSelectors.wordFilterSelector)
  const disabled = useSelector(questionPanelSelectors.disablePersonalisationsSelector)
  const part = useSelector(state => customizationSelectors.partFromQuestionIdSelector(state, id))
  const { charactersCounterColor } = useThemeSettings('textInputAndDropdown')
  const isMultiline = customizerProductsUtils.isMultilineTextAnswer(part?.position)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  const previousCursorPositionRef = useRef<CursorSelection>({ selectionStart: 0, selectionEnd: 0 })

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
    event.preventDefault()
    previousCursorPositionRef.current = {
      selectionStart: event.target.selectionStart || 0,
      selectionEnd: event.target.selectionEnd || 0,
    }

    const hasMaxLengthBeenReached = question.maxLength != null && event.target.value.length > question.maxLength
    const areInputCharactersInvalid =
      !isNullOrEmpty(event.target.value) && !areInputCharactersValid(inputCharacters, event.target.value, allowDecimals)

    if (hasMaxLengthBeenReached || areInputCharactersInvalid) return

    let filteredWord
    if (wordFilter.isActive && !isNullOrEmpty(event.target.value)) {
      filteredWord = validateWordFiltered(wordFilter, event.target.value)
      dispatch(customizationActions.setQuestionHasFilteredWord(id, filteredWord.isProfane))
    }

    if (selectedAnswer?.isPersonalisation) {
      updateTextAnswer(id, selectedAnswer.id, event.target.value, filteredWord?.cleanedValue)
    } else {
      createTextAnswer(id, event.target.value, filteredWord?.cleanedValue)
    }
  }

  const handleFocus: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
    dispatch(customizationActions.editQuestion(id))
    onFocus(event)
  }

  const handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
    if (inputCharacters === 'number' && allowDecimals) {
      const value = event.target.value
      if (value.endsWith('.')) {
        handleChange({ ...event, preventDefault: () => null, target: { ...event.target, value: value.slice(0, -1) } })
      } else if (value.startsWith('.')) {
        handleChange({ ...event, preventDefault: () => null, target: { ...event.target, value: '0' + value } })
      }
    }
  }

  useEffect(() => {
    if (!selectedAnswer?.value) return
    const inputElement = isMultiline ? textAreaRef.current : inputRef.current

    inputElement?.setSelectionRange(
      previousCursorPositionRef.current.selectionStart,
      previousCursorPositionRef.current.selectionEnd
    )
  }, [selectedAnswer?.value])

  const style = {
    backgroundColor: disabled ? undefined : textInputAndDropdownStyle.fieldBackgroundColor,
    borderRadius: textInputAndDropdownStyle.fieldBorderRadius,
    borderColor: !disabled ? undefined : textInputAndDropdownStyle.fieldBorderColor,
    outlineColor: disabled ? undefined : textInputAndDropdownStyle.fieldBorderColor,
    color: textInputAndDropdownStyle.fieldFontColor,
    fontSize: textInputAndDropdownStyle.fieldFontSize,
    fontFamily: textInputAndDropdownStyle.fontFamily?.fontFamily,
  }

  return (
    <div className="text-option" data-testid="text-question">
      <div className={`input-with-warning ${className}`}>
        {!isMultiline && (
          <TextQuestionInput
            ref={inputRef}
            type={type}
            autoComplete="false"
            maxLength={maxLength}
            onChange={handleChange}
            onBlur={handleBlur}
            value={selectedAnswer?.value || ''}
            placeholder={placeholder}
            disabled={disabled}
            style={{ ...style, height: textInputAndDropdownStyle.fieldHeight }}
            onFocus={handleFocus}
            color={textInputAndDropdownStyle.fieldFontColor}
            data-testid="text-question-input"
          />
        )}
        {isMultiline && (
          <TextAreaInput
            ref={textAreaRef}
            autoComplete="false"
            maxLength={maxLength}
            onChange={handleChange}
            onBlur={handleBlur}
            value={selectedAnswer?.value || ''}
            placeholder={placeholder}
            style={style}
            disabled={disabled}
            onFocus={handleFocus}
            minRows={minRows}
            maxRows={maxRows}
            color={textInputAndDropdownStyle.fieldFontColor}
            data-testid="text-question-input"
          />
        )}
        {showCharacterCount && (
          <div
            className={classNames('characters-counter', {
              maxed: selectedAnswer?.value && maxLength && selectedAnswer.value.length >= maxLength,
            })}
            style={{ color: charactersCounterColor }}
          >
            {selectedAnswer?.value?.length || 0}/{maxLength || 0}
          </div>
        )}
        {children}
      </div>
    </div>
  )
}

export default TextQuestion

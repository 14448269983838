import { DenormalizedQuote } from '@packages/types'
import { View, Text, StyleSheet } from '@react-pdf/renderer'
import React from 'react'

import { NEUTRAL_400, NEUTRAL_600 } from './assets'

interface PrintableQuoteCustomerDetailsProps {
  quote: DenormalizedQuote
}

const styles = StyleSheet.create({
  section: {
    paddingBottom: 20,
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
    gap: 4,
  },
  title: {
    color: NEUTRAL_400,
    marginBottom: 4,
  },
  customerDetails: {
    color: NEUTRAL_600,
  },
})

const PrintableQuoteCustomerDetails = ({ quote }: PrintableQuoteCustomerDetailsProps) => {
  return (
    <View style={styles.section}>
      <Text style={styles.title}>Client information</Text>
      <Text style={styles.customerDetails}>{quote.customer.name}</Text>
      <Text style={styles.customerDetails}>{quote.customer.email}</Text>
    </View>
  )
}

export default PrintableQuoteCustomerDetails

import { DefaultQuoteForm, Field } from '@packages/types'
import { AnyAction } from 'redux'

import * as actionTypes from './actionTypes'
import quoteConfig from './config.json'
import { defaultQuoteForm } from './constants'
import { QuoteRequestStatus } from './types'

export type QuoteState = {
  isFormVisible: boolean
  submitError: string | null
  form: DefaultQuoteForm
  formValues: Partial<Record<Field['key'], string>>
  formErrors: null | Record<Field['key'], string | undefined>
  hasErrors: boolean
  status: QuoteRequestStatus
  texts: Record<string, { key: string; text: string }>
  quoteId: string | null
}

const initialState: QuoteState = {
  isFormVisible: false,
  form: defaultQuoteForm,
  formValues: {},
  submitError: null,
  formErrors: null,
  hasErrors: false,
  texts: quoteConfig.texts,
  status: QuoteRequestStatus.idle,
  quoteId: null,
}

export default (state: QuoteState = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.SET_MODAL_VISIBILITY:
      const clearState = !action.payload && state.status === 'success'
      return {
        ...state,
        ...(clearState && initialState),
        isFormVisible: action.payload,
      }
    case actionTypes.UPDATE_FORM_FIELD:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.payload.fieldKey]: action.payload.value,
        },
      }
    case actionTypes.SET_FORM_ERRORS:
      const newFormErrors = {
        ...state.formErrors,
        ...(action.payload as Record<Field['key'], string | undefined>),
      }
      return {
        ...state,
        formErrors: newFormErrors,
        hasErrors: Object.values(newFormErrors).some(value => !!value),
      }
    case actionTypes.SET_REQUEST_STATUS:
      return {
        ...state,
        status: action.payload as QuoteRequestStatus,
      }
    case actionTypes.RETRY:
      return {
        ...state,
        status: QuoteRequestStatus.idle,
      }
    case actionTypes.SET_QUOTE_ID:
      return {
        ...state,
        quoteId: action.payload,
      }
    default:
      return state
  }
}

import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { Button, TopBarEnvTag } from 'common/components'
import { TenantContext } from 'common/tenant'
import { useCurrentUser } from 'common/users'
import SkaterLogo from 'icons/logos/skater.svg'

import FreeTrialIndicator from './FreeTrialIndicator'

const Header = () => {
  const tenant = useContext(TenantContext)
  const history = useHistory()
  const { currentUser } = useCurrentUser()

  const handleLogOut = () => {
    localStorage.removeItem('bearer')
    history.push('/login')
  }

  if (!currentUser) return null

  return (
    <header className="print:hidden bg-white fixed top-0 flex justify-between items-center w-full border-b border-b-neutral-100 h-[52px] py-4 pl-5 pr-5 md:pr-[44px] z-[5]">
      <TopBarEnvTag />

      <Link to="/" className="flex items-center space-x-2">
        <SkaterLogo className="w-4" />
      </Link>
      {tenant && <FreeTrialIndicator />}
      <Button variant="subtle" onClick={handleLogOut}>
        Log out
      </Button>
    </header>
  )
}

export default Header

import { AnswerType, FontType, supportedFontExtensions, supportedImageExtensions } from '@packages/types'
import classNames from 'classnames'
import { formatDistanceToNow } from 'date-fns'
import React, { useEffect } from 'react'

import { IconButton } from 'common/components'
import BinIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'
import DownloadIcon from 'icons/bold/05-Internet-Networks-Servers/08-Upload-Download/download-bottom.svg'
import { downloadResource } from 'utils/forceDownload'
import { FontService } from 'utils/loaders'
import ImageLoader, { dimensionStrings } from 'utils/loaders/ImageLoader'

const TYPES = {
  [AnswerType.Image]: supportedImageExtensions,
  [AnswerType.Font]: supportedFontExtensions,
} as { [key: string]: string[] }

interface FileInfoProps {
  id: string
  url: string
  filename: string
  date?: string
  isSingleLine?: boolean
  onDeleteClick: () => void
}

const FileInfo = ({ id, url, filename, date, isSingleLine = false, onDeleteClick }: FileInfoProps) => {
  const handleDownload: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()
    downloadResource(url)
  }

  const handleDelete: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()
    onDeleteClick()
  }

  const splittedUrl = url.split('.')
  const extension = splittedUrl[splittedUrl.length - 1]

  const type = Object.keys(TYPES).find(type => TYPES[type].includes(extension))

  useEffect(() => {
    FontService.loadFont({ fontFamily: id, type: FontType.Custom, asset: { [extension]: { url } } })
  }, [id])

  return (
    <div
      className={classNames(
        'relative group flex bg-white border-neutral-100 border border-solid shadow-xs !pl-0 w-full rounded-md hover:bg-neutral-50 overflow-hidden',
        {
          'h-[60px] mb-0': !isSingleLine,
          'h-[48px] items-center mb-1': isSingleLine,
        }
      )}
    >
      {type === AnswerType.Image && (
        <img
          src={ImageLoader.buildSrc(url, { preventResize: false, dimensionString: dimensionStrings.regular })}
          className="object-contain overflow-hidden h-full basis-12 shrink-0 border-r rounded-l-md border-neutral-100"
        />
      )}

      {type === AnswerType.Font && (
        <>
          <span
            className={classNames(
              'text-2xl p-1 text-white basis-8 shrink-0 rounded bg-neutral-700 flex items-center justify-center',
              {
                'h-[58px] leading-[58px]': !isSingleLine,
                'h-[46px] leading-[46px]': isSingleLine,
              }
            )}
            role="presentation"
            aria-label="font preview"
          >
            <div style={{ fontFamily: `${id}` }}>
              <span>aA</span>
            </div>
          </span>
        </>
      )}

      <div className="flex flex-col overflow-hidden ml-3 mr-3 justify-center">
        <span className="truncate text-xs">{filename || `Untitled ${type}`}</span>
        {!isSingleLine && (
          <span className="truncate text-neutral-300 text-xs">
            {date ? formatDistanceToNow(new Date(date), { addSuffix: true }) : 'Date unavailable'}
          </span>
        )}
      </div>
      <div className="absolute right-0 h-full flex items-center mr-3 space-x-1 opacity-0 w-0 group-hover:opacity-100 group-hover:w-auto">
        <IconButton type="button" onClick={handleDownload} Icon={DownloadIcon} small aria-label="download thumbnail" />
        <IconButton type="button" onClick={handleDelete} Icon={BinIcon} small aria-label="remove thumbnail" />
      </div>
    </div>
  )
}

export default FileInfo

import React from 'react'

import { ProgressBar } from 'common/components'
import CheckCircle from 'icons/bold/01-Interface Essential/33-Form-Validation/check-circle-1.svg'
import RemoveIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'

interface CompletedProgressCenterProps {
  onClose: () => void
}

const CompletedProgressCenter = ({ onClose }: CompletedProgressCenterProps) => (
  <div className="relative flex flex-col border-neutral-100 border-t p-4">
    <button
      role="button"
      aria-label="Close progress center"
      type="button"
      onClick={onClose}
      className="absolute top-0 right-0 p-2"
    >
      <RemoveIcon className="fill-neutral-300" width={10} height={10} />
    </button>
    <div className="flex items-center justify-between mt-6">
      <h2>You're all set up!</h2>
      <CheckCircle className="w-4 h-4 fill-tertiary-green-300" />
    </div>
    <ProgressBar percentage={100} className="!bg-tertiary-green-300" />
    <span className="font-p2">You've completed all the steps!</span>
  </div>
)

export default CompletedProgressCenter

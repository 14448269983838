import { createContext } from 'react'

import { ConsentStatus } from '../types'

const TrackerContext = createContext<{ send: (event: string, data?: object) => void; status?: ConsentStatus }>({
  send: () => {},
  status: undefined,
})

export default TrackerContext

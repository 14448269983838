import countries from '@packages/data/countries.json'
import { Brand, BrandBusiness } from '@packages/types'
import { UseMutateFunction } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React from 'react'
import * as yup from 'yup'

import { NetworkError } from 'common/api'
import { Button, Card, HelperText, Input, InputField, Label, Select } from 'common/components'
import { withFlag } from 'common/users/components'

import { BrandUpdateQuery } from '../types'

const phoneRegExp = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

const businessFormValidation = yup.object().shape({
  name: yup.string(),
  email: yup.string().email('Please enter a valid email'),
  phone: yup.string().matches(phoneRegExp, 'Please enter a valid phone number'),
  address: yup.object().shape({
    address: yup.string(),
    city: yup.string(),
    countryCode: yup.string(),
    provinceCode: yup.string(),
    apartment: yup.string(),
    zip: yup.string(),
  }),
})

interface BusinessFormProps {
  infos: BrandBusiness
  isSaving?: boolean
  onSubmit: UseMutateFunction<Brand, NetworkError, BrandUpdateQuery>
}

const BusinessForm = ({ infos, isSaving, onSubmit }: BusinessFormProps) => {
  const formik = useFormik<BrandBusiness>({
    initialValues: infos,
    validationSchema: businessFormValidation,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => onSubmit({ business: values }, { onSettled: () => setSubmitting(false) }),
  })

  const countriesOptions = countries.map(country => ({
    value: country.countryShortCode,
    label: country.countryName,
  }))

  const provinceOptions = countries
    .find(country => country.countryShortCode === formik.values?.address?.countryCode)
    ?.regions.map(region => ({
      value: region.shortCode,
      label: region.name,
    }))

  return (
    <Card className="flex flex-1 flex-col">
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className="p-6">
          <InputField className="mb-6">
            <Label htmlFor="name">Business name</Label>
            <Input
              placeholder="Enter name"
              id="name"
              name="name"
              value={formik.values?.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </InputField>
          <InputField className="mb-6">
            <Label htmlFor="email">Email</Label>
            <Input
              placeholder="example@email.com"
              id="email"
              name="email"
              value={formik.values?.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasError={formik.touched.email && formik.errors.email != null}
            />
            {formik.touched.email && formik.errors.email != null && (
              <HelperText hasError>{formik.errors.email}</HelperText>
            )}
          </InputField>
          <InputField className="mb-6">
            <Label htmlFor="phone">Phone number</Label>
            <Input
              placeholder="+1 (123) 123 1234"
              id="phone"
              name="phone"
              value={formik.values?.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasError={formik.touched.phone && formik.errors.phone != null}
            />
            {formik.touched.phone && formik.errors.phone != null && (
              <HelperText hasError>{formik.errors.phone}</HelperText>
            )}
          </InputField>
          <InputField className="mb-6">
            <Label htmlFor="country">Country</Label>
            <Select
              id="country"
              name="country"
              menuPlacement="auto"
              placeholder="Select country"
              isSearchable
              options={countriesOptions}
              value={countriesOptions.find(option => option.value === formik.values?.address?.countryCode) || null}
              onChange={option => formik.setFieldValue('address.countryCode', option?.value)}
            />
          </InputField>
          <InputField className="mb-6">
            <Label htmlFor="address">Address</Label>
            <Input
              placeholder="Enter street address"
              id="address"
              name="address.address"
              value={formik.values?.address?.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </InputField>
          <InputField className="mb-6">
            <Label htmlFor="apartment">Apartment, suite, etc.</Label>
            <Input
              placeholder="Enter apartment, suite, etc"
              id="apartment"
              name="address.apartment"
              value={formik.values?.address?.apartment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </InputField>
          <div className="mb-6">
            <div className="flex">
              <InputField className="w-1/2 mr-4">
                <Label htmlFor="city">City / Town</Label>
                <Input
                  placeholder="Enter city/town"
                  id="city"
                  name="address.city"
                  value={formik.values?.address?.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </InputField>
              <InputField className="w-1/2">
                <Label htmlFor="province">State / Region / Province</Label>
                <Select
                  placeholder="Enter state/region/province"
                  id="province"
                  name="province"
                  menuPlacement="auto"
                  isSearchable
                  options={provinceOptions}
                  value={provinceOptions?.find(option => option.value === formik.values?.address?.provinceCode) || null}
                  onChange={option => formik.setFieldValue('address.provinceCode', option?.value)}
                  menuPortalTarget={document.body}
                />
              </InputField>
            </div>
          </div>
          <InputField className="mb-6">
            <Label htmlFor="zip">Zip / Postal code</Label>
            <Input
              placeholder="Enter zip/postal code"
              id="zip"
              name="address.zip"
              value={formik.values?.address?.zip}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </InputField>
        </div>
        <Card.Footer>
          <Button type="reset" onClick={() => formik.resetForm()} disabled={!formik.dirty || isSaving}>
            Discard
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={!formik.isValid || !formik.dirty || isSaving}
            isLoading={isSaving}
          >
            Save
          </Button>
        </Card.Footer>
      </form>
    </Card>
  )
}

export default withFlag({
  Component: BusinessForm,
  feature: 'quote_phase_1',
})

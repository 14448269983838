import React from 'react'

import { TopBar as CommonTopBar } from 'common/components'

import './TopBar.scss'

interface TopBarProps {
  children: React.ReactNode
}

const TopBar = ({ children }: TopBarProps) => <CommonTopBar>{children}</CommonTopBar>

export default TopBar

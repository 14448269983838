import { Addon } from '@packages/types'
import React from 'react'

import { Button, Card, Tag } from 'common/components'

interface AddonCardProps {
  subscriptionAddons: Addon[]
  availableAddons: Addon[]
  onAdd: (addon: Addon) => void
  onRemove: (addon: Addon) => void
}

const AddonCard = ({ subscriptionAddons: subscripedAddons, availableAddons, onAdd, onRemove }: AddonCardProps) => {
  return (
    <div className="flex flex-col">
      <div className="h-[401px] w-[410px] flex-col rounded-xl bg-neutral-100">
        <Card className="h-[230px] w-[410px] flex-col bg-white">
          <Card.Section className="md:flex-col">
            <h1>Addon</h1>
          </Card.Section>
          <Card.Separator />
          {availableAddons.map((addon, index) => {
            const isAdded = !!subscripedAddons.find(subAddon => subAddon.addonId === addon.addonId)
            return (
              <Card.Section key={index} className="md:flex-col">
                <div className="flex justify-between">
                  <span className="font-medium h-5">{addon.name}</span>
                  {isAdded && (
                    <Tag
                      aria-label={`added ${addon.name}`}
                      className="rounded bg-tertiary-green-75 text-tertiary-green-500"
                    >
                      Added
                    </Tag>
                  )}
                </div>
                <span className="pt-2 text-neutral-400">{addon.description}</span>
                <div className="items-center w-full flex mt-2 justify-end">
                  <span className="h-fit w-fit mr-2 text-primary-600">+${addon.price} monthly</span>
                  {isAdded && (
                    <Button variant="default-error" onClick={() => onRemove(addon)}>
                      Remove
                    </Button>
                  )}
                  {!isAdded && (
                    <Button variant="default" onClick={() => onAdd(addon)}>
                      Add
                    </Button>
                  )}
                </div>
              </Card.Section>
            )
          })}
        </Card>
      </div>
    </div>
  )
}

export default AddonCard

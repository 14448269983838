import React, { forwardRef } from 'react'

import { PatchTextInput as CommonPatchTextInput } from 'common/components'
import {
  PatchTextInputProps as CommonPatchTextInputProps,
  PatchTextInputHandle,
} from 'common/components/PatchTextInput'

interface PatchTextInputProps extends CommonPatchTextInputProps {
  filled?: boolean
}

const PatchTextInput = forwardRef<PatchTextInputHandle, PatchTextInputProps>(
  ({ type = 'text', filled = true, ...props }, ref) => {
    return <CommonPatchTextInput ref={ref} type={type} className="!px-2" {...props} />
  }
)

export default PatchTextInput

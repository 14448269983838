import { Asset } from '@packages/types'
import { useMutation } from '@tanstack/react-query'
import { useEffect, useRef, useState } from 'react'

import { trpc } from 'common/hooks/trpc'

import { validateFiles } from '../utils'
import useAssetService from './useAssetService'

interface useFilesUploaderProps {
  disabled?: boolean
  extensions: string[]
  onFilesUploaded: (assets: Asset[] | null) => void
}

const useFilesUploader = ({ disabled, onFilesUploaded, extensions }: useFilesUploaderProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const droppableAreaRef = useRef<HTMLDivElement | null>(null)
  const dragTargets = useRef<EventTarget[]>([])
  const [isDraggingOver, setIsDraggingOver] = useState(false)
  const { mutate: createActivityLog } = trpc.activityLog.create.useMutation()
  const assetService = useAssetService()

  const { mutateAsync: uploadAssets } = useMutation(assetService.upload)

  const uploadFiles = async (files: FileList | null) => {
    const { validFiles, invalidFiles } = validateFiles(files, { extensions, maxFileSizeInMB: 100 })

    if (invalidFiles.length > 0) {
      const message = `The following files are invalid and were not uploaded:\n\n${invalidFiles.map(file => file.name).join('\n')}`
      alert(message)
    }

    if (validFiles.length > 0) {
      const assets = await uploadAssets(validFiles)
      onFilesUploaded(assets)
      createActivityLog({
        type: 'uploadAsset',
        details: { type: assets[0].mimetype?.split('/')[0] || '', extension: assets[0].filename.split('.').pop()! },
      })
    } else {
      onFilesUploaded(null)
    }
  }

  const onChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    event.preventDefault()
    if (!disabled) uploadFiles(event.target.files)
  }

  const trigger = () => {
    if (disabled) return

    if (fileInputRef.current) {
      fileInputRef.current.value = ''
      fileInputRef.current.click()
    }
  }

  const onDragOver: React.DragEventHandler<HTMLElement> = event => {
    event.preventDefault()
    event.stopPropagation()

    try {
      event.dataTransfer.dropEffect = 'copy'
    } catch (err) {}

    return false
  }

  const onDragEnter: React.DragEventHandler<HTMLElement> = event => {
    event.preventDefault()
    if (!dragTargets.current.includes(event.target)) dragTargets.current.push(event.target)
    setIsDraggingOver(true)
  }

  const onDragLeave: React.DragEventHandler<HTMLElement> = event => {
    event.preventDefault()
    dragTargets.current = dragTargets.current.filter(
      el => el !== event.target && droppableAreaRef.current?.contains(el as Node)
    )

    if (dragTargets.current.length === 0) setIsDraggingOver(false)
  }

  const onDrop: React.DragEventHandler<HTMLElement> = event => {
    event.preventDefault()
    setIsDraggingOver(false)
    dragTargets.current = []
    if (!disabled) uploadFiles(event.dataTransfer.files)
  }

  useEffect(() => {
    const preventDefaultDropEvent = (event: DragEvent) => {
      if (droppableAreaRef.current?.contains(event.target as Node)) return
      event.preventDefault()
      dragTargets.current = []
    }

    window.addEventListener('dragover', preventDefaultDropEvent, false)
    window.addEventListener('drop', preventDefaultDropEvent, false)

    return () => {
      window.removeEventListener('dragover', preventDefaultDropEvent)
      window.removeEventListener('drop', preventDefaultDropEvent)
    }
  }, [])

  return {
    trigger,
    isDraggingOver,
    fileInputProps: {
      ref: fileInputRef,
      onChange,
      type: 'file',
      style: { display: 'none' },
      disabled,
      accept: extensions.map(extension => `.${extension}`).join(','),
    },
    droppableAreaProps: {
      ref: droppableAreaRef,
      onDragEnter,
      onDragOver,
      onDragLeave,
      onDrop,
    },
  }
}

export default useFilesUploader

import React from 'react'

import { Button, Card, useModal } from 'common/components'

import EntreprisePlanModal from '../modals/EntreprisePlanModal'

const EntreprisePlanCard = () => {
  const entrepriseModal = useModal()

  return (
    <>
      <Card
        className="bg-transparent border border-solid border-neutral-100 items-center justify-between p-3"
        shadow={false}
      >
        <span className="font-p1" aria-label="entreprise plan short description">
          Enterprise plan available for large payments volumes or high value transactions.
        </span>
        <Button variant="default" onClick={entrepriseModal.open}>
          View plan
        </Button>
      </Card>
      {entrepriseModal.isVisible && (
        <EntreprisePlanModal onClose={entrepriseModal.close} {...entrepriseModal.modalProps} />
      )}
    </>
  )
}

export default EntreprisePlanCard

import { Text } from '@react-pdf/renderer'
import React from 'react'

import formatMoney from 'utils/formatMoney'

interface PriceProps {
  amount: number
  currency: string
}

const Price = ({ amount, currency }: PriceProps) => {
  return <Text>{formatMoney(amount, { currency })}</Text>
}

export default Price

import { Answer } from '@packages/types'
import classNames from 'classnames'
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import imageLoader, { dimensionStrings, getThumbnailDimensionString } from 'utils/loaders/ImageLoader'

import withAnswerTheme, { WithAnswerThemeProps } from './withAnswerTheme'

import 'react-lazy-load-image-component/src/effects/blur.css'
import './ImageAnswer.scss'

const loadedImages: string[] = []

const chopPx = (value: string) => value.replace('px', '')

type ImageAnswerProps = Answer &
  WithAnswerThemeProps & {
    noResize?: boolean
    imageStyle?: React.CSSProperties
  }

const ImageAnswer = withAnswerTheme<ImageAnswerProps>(
  ({ thumbnail, value, style, extraClassNames, noResize = false, imageStyle }) => {
    const width = style.width && chopPx(style.width)
    const height = style.height && chopPx(style.height)

    const src =
      thumbnail !== null || !noResize
        ? imageLoader.buildSrc(value!, { dimensionString: getThumbnailDimensionString(width) })
        : imageLoader.buildSrc(value!, { preventResize: true })

    const placeholderSrc =
      (noResize === false && imageLoader.buildSrc(value!, { dimensionString: dimensionStrings.mini })) || undefined

    return (
      <LazyLoadImage
        effect="blur"
        src={src}
        width={width}
        height={height}
        wrapperStyle={{ ...style, display: 'flex', boxSizing: 'border-box' }}
        wrapperClassName={classNames('image-choice', extraClassNames)}
        imageStyle={imageStyle}
        visibleByDefault={loadedImages.includes(src)}
        threshold={200}
        placeholder={<img src={placeholderSrc} />}
        placeholderThreshold={500}
        crossOrigin="anonymous"
        onLoad={() => setTimeout(() => loadedImages.push(src), 600)}
        data-testid="image-answer"
      />
    )
  }
)

export default ImageAnswer

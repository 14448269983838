import React from 'react'

import { actions as navigationActions } from 'builder/build/navigation'
import { LeftPanel } from 'builder/build/navigation/types'
import { useDispatch } from 'cms/hooks'
import { UniversalSearchList } from 'common/components'
import ReorderDownIcon from 'icons/core-solid/interface-essential/interface-align-layers-1.svg'

interface QuickActionReorderLayersProps {
  onClick: () => void
  query: string
}

const QuickActionReorderLayers = ({ onClick, query }: QuickActionReorderLayersProps) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(navigationActions.setLeftPanel(LeftPanel.layers))
    onClick()
  }

  return (
    <UniversalSearchList.Result onClick={handleClick} className="text-primary-500">
      <UniversalSearchList.HighlightableText text="Reorder layers" highlight={query} />
      <div className="ml-auto">
        <div className="flex space-x-1 text-xs text-neutral-300 items-center">
          <ReorderDownIcon className="h-2.5 w-2.5 fill-neutral-300" />
        </div>
      </div>
    </UniversalSearchList.Result>
  )
}

export default QuickActionReorderLayers

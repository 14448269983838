import React from 'react'
import { connect } from 'react-redux'
import { Store } from 'redux'

import { Plugin, State, PluginComponent as PluginComponentType } from 'plugins/types'

import PluginComponent from './PluginComponent'
import PluginCustomComponentContext from './PluginCustomComponentContext'

interface PluginCustomComponentRegistryProps {
  children: React.ReactNode
  plugins: Plugin[]
  store: Store<State>
}

export class PluginCustomComponentRegistry extends React.Component<PluginCustomComponentRegistryProps> {
  override state: { components: Record<string, PluginComponentType> } = {
    components: {},
  }

  loadedKeys: string[] = []

  override componentDidMount() {
    this.loadPluginComponents()
  }

  override componentDidUpdate() {
    this.loadPluginComponents()
  }

  loadPluginComponents() {
    const { plugins, store } = this.props

    plugins.forEach(async plugin => {
      if (!this.loadedKeys.includes(plugin.id)) {
        this.loadedKeys.push(plugin.id)
        try {
          const imports = await import(`plugins/${plugin.location}`)
          imports.default.script.initialize(store, plugin.settings)
          this.setState({
            components: {
              ...this.state.components,
              ...(imports.default.components as PluginComponentType[]).reduce<Record<string, PluginComponentType>>(
                (componentMap, Component) => {
                  Component.pluginId = plugin.id
                  componentMap[Component.pluginMetadata.key] = Component
                  return componentMap
                },
                {}
              ),
            },
          })
        } catch (e) {
          console.error(e)
        }
      }
    })
  }

  override render() {
    const { children } = this.props
    const { components } = this.state

    const portalComponents = Object.values(components).filter(component => component.pluginMetadata.type === 'portal')

    return (
      <PluginCustomComponentContext.Provider value={components}>
        {portalComponents.length > 0 &&
          portalComponents.map(component => {
            return (
              <PluginComponent
                key={component.pluginMetadata.name}
                componentKey={component.pluginMetadata.key}
                {...this.props}
              />
            )
          })}

        {children}
      </PluginCustomComponentContext.Provider>
    )
  }
}

const mapStateToProps = ({ plugins }: State) => {
  return {
    plugins,
  }
}

export default connect(mapStateToProps)(PluginCustomComponentRegistry)
